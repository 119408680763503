import { supabase } from '../config/supabase';
const statusCode = [200, 201, 204];

export const useAddParticipant = async sessionData => {
	try {
		const { session_id, first_name, last_name, private_note } = sessionData;
		// first get session data.
		const upperCaseFirstName =
			first_name.charAt(0).toUpperCase() + first_name.slice(1);

		const lowerCaseLastName =
			last_name.charAt(0).toUpperCase() + last_name.slice(1);

		return await supabase
			.from('session')
			.select()
			.eq('id', session_id)
			.then(async session_item => {
				if (statusCode.includes(session_item.status)) {
					// get session participant list.
					return await supabase
						.from('participant')
						.select()
						.eq('session_id', session_id)
						.then(async item => {
							if (statusCode.includes(item.status)) {
								let is_paid = 0;
								if (session_item?.data[0]?.amount === 0) {
									is_paid = 1;
								}
								// Find the first object with the matching first_name and last_name
								const result = item?.data.find(
									item =>
										item.first_name === upperCaseFirstName &&
										item.last_name === lowerCaseLastName
								);
								if (result && !sessionData.isSearchClick) {
									return {
										error: !sessionData?.isSearchClick
											? 'Someone with this name is already in the session.'
											: null,
										data: null,
										count: null,
										status: 201,
									};
								}
								if (
									item?.data.length !== session_item?.data[0].no_of_participant
								) {
									if (result) {
										return {
											error: !sessionData?.isSearchClick
												? 'Someone with this name is already in the session.'
												: null,
											data: null,
											count: null,
											status: 201,
										};
									} else {
										// adding particiapnt in particiapnt table.
										return await supabase
											.from('participant')
											.insert({
												session_id,
												first_name: upperCaseFirstName,
												last_name: lowerCaseLastName,
												private_note,
												is_paid,
											})
											.select()
											.then(participant_item => {
												if (statusCode.includes(participant_item.status)) {
													return participant_item;
												}
											});
									}
								} else {
									return {
										isTrue: true,
										error:
											'This session is full. Please ask the facilitator for assistance.',
										data: null,
										count: null,
										status: 201,
									};
								}
							}
						});
				}
			});
	} catch (error) {}
};

export const useFetchParticipants = async id => {
	try {
		if (!id) return;
		let { data } = await supabase
			.from('participant')
			.select(
				'*, team_participant (*, role_id(id, name), team_id(id, name, team_index))'
			)
			.eq('session_id', id);
		return data;
	} catch (error) {}
};

export const useDeleteParticipants = async item => {
	try {
		await supabase
			.from('participant')
			.select()
			.eq('id', item?.id)
			.then(async participant_item => {
				if (
					statusCode.includes(participant_item.status) &&
					participant_item.data.length > 0
				) {
					await supabase
						.from('team_participant')
						.update({
							participant_id: null,
							// current_status: 0,
						})
						.match({
							session_id: Number(participant_item.data[0].session_id),
							participant_id: Number(item?.id),
						})
						.then(async () => {
							await supabase
								.from('games')
								.update({ participant_id: null })
								.match({
									session_id: Number(participant_item.data[0].session_id),
									participant_id: Number(item?.id),
								});
							const { error } = await supabase
								.from('participant')
								.delete()
								.eq('id', item?.id);
							if (error) {
							}

							return 'Delete Successfully';
						});
				} else {
					return 'Participant DeleteErr';
				}
			});
	} catch (error) {}
};

export const useDeleteSingleParticipant = async id => {
	try {
		await supabase
			.from('team_participant')
			.select()
			.eq('session_id', id.id)
			.order('id', { ascending: true })
			.then(async participant_item => {
				const data = participant_item.data.reverse();
				if (data.length > 4) {
					for (let i = 0; i < id.remove_count; i++) {
						await supabase
							.from('team_participant')
							.delete()
							.eq('id', data[i].id);
						await supabase
							.from('participant')
							.delete()
							.eq('id', data[i].participant_id);
					}
					await supabase
						.from('organization')
						.select()
						.eq('id', id?.org_id)
						.then(async org_data => {
							await supabase
								.from('organization')
								.update({
									available_seat:
										Number(org_data.data[0].available_seat) +
										Number(id.remove_count),
								})
								.eq('id', org_data.data[0].id);
						});
				}
			});
		return;
	} catch (error) {}
};
export const usePatchParticipant = async sessionData => {
	try {
		const { id, first_name, last_name, private_note } = sessionData;
		const upperCaseFirstName =
			first_name.charAt(0).toUpperCase() + first_name.slice(1);
		const lowerCaseLastName =
			last_name.charAt(0).toUpperCase() + last_name.slice(1);

		supabase
			.from('participant')
			.update({
				first_name: upperCaseFirstName,
				last_name: lowerCaseLastName,
				private_note,
			})
			.eq('id', Number(id))
			.select()
			.then(item => {
				if (statusCode.includes(item.status)) {
					return 'Update Successfully';
				}
			});
	} catch (error) {}
};

export const useParticipantTeamDataApi = async item => {
	try {
		const { data, error } = await supabase
			.from('team_participant')
			.select('*')
			.eq('session_id', item.sessionId)
			.eq('team_id', item.teamId);
		if (error) throw new Error(error.message);
		return data;
	} catch (error) {
		if (error) throw new Error(error.message);
	}
};
